/* The container */
.container {
  display: block;
  position: relative;
  /* padding-left: 35px;
  margin-bottom: 12px; */
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.admin-link {
  color: #2a73b3;
}

.password-text {
  font-size: 12px;
}

/* Hide the browser's default checkbox */
.container input {
  /* position: absolute; */
  /* opacity: 0; */
  cursor: pointer;
  /* height: 0; */
  /* width: 0; */
}

.main-heading {
  color: #204654;
  font-size: 20px;
  font-weight: 700;
}

.checkbox-container input {
  opacity: 0;
}

/* Create a custom checkbox */
.checkmark {
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 8.5px;
  top: 5px;
  width: 7px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.App {
  text-align: center;
  background-color: #f2f2f2;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* drop down overflow fix */
.navbar .dropdown-menu {
  cursor: pointer;
}

/* drop down overflow fix */

/* required field styling */
.nav {
  box-shadow: 1px 4px 13px 1px #969595;
}

.hint-table-span {
  color: #655959;
}

label {
  color: #2e6680;
  font-size: 15px;
  font-weight: 700;
}

label span {
  color: red;
  font-size: 22px;
  position: absolute;
  line-height: 20px;
  font-weight: 700;
}

.App-link {
  color: #09d3ac;
}

.home-header {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.bg-blue {
  background-color: #c3ceda;
}

.review-color {
  background-color: #c7791a !important;
}

.btn-blue {
  background-color: #327fb5;
  border-color: #327fb5;
  color: white;
}
.btn-red {
  background-color: #c23c32;
  border-color: #327fb5;
  color: white;
}

.btn-yellow {
  background: #ffc10780 !important;
}

#admin-dropdown {
  left: 0;
}

.color-text {
  color: #1d1f23 !important;
}

/*  Login Page CSS */
.login-container {
  /* margin-top: 5%;
  margin-bottom: 5%; */
}

.login-container .content {
  height: 100vh;
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0 15px;
}

.login-form-1 {
  padding: 5%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}

.login-form-1 h3 {
  text-align: center;
  color: #333;
  margin-bottom: 40px;
  margin-top: 20px;
}

.login-form-2 {
  padding: 5%;
  background: #0062cc;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}

.login-form-2 h3 {
  text-align: center;
  color: #fff;
}

.login-container form {
  padding: 0 10% 10% 10%;
}

.btnSubmit {
  width: 50%;
  border-radius: 1rem;
  padding: 1.5%;
  border: none;
  cursor: pointer;
}

.login-form-1 .btnSubmit {
  font-weight: 600;
  color: #fff;
  background-color: #0062cc;
}

.login-form-2 .btnSubmit {
  font-weight: 600;
  color: #0062cc;
  background-color: #fff;
}

.login-form-2 .ForgetPwd {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}

.login-form-1 .ForgetPwd {
  color: #0062cc;
  font-weight: 600;
  text-decoration: none;
}

.bg-dark-blue {
  background-color: #142d47;
  color: white;
}

.bg-light-blue {
  background-color: #3369a3;
  color: white;
}

.border-radius {
  border: none;
  border-radius: 11px;
}

.margin-left {
  margin-left: 240px !important;
}

.navbar-margin-right {
  margin-right: 80px;
}

.hamburger-icon {
  background-image: url('/hamburger-svg.svg') !important;
}

.submit-btn {
  padding: 8px 0;
  border-radius: 5px;
  color: #3c3c3c;
  position: relative;
}

.spinner-border {
  width: 1.6rem !important;
  height: 1.6rem !important;
  margin-top: 10px;
  position: absolute;
}

.textArea {
  background: #e8e7e7;
  border-radius: 0;
  text-align: left;
  padding: 1px 10px;
}

.textArea p {
  margin: 6px 0;
}

.textArea p span {
  color: #00adee;
}

/* accordion styling */
.accordion > .card {
  background: #efefef;
  margin: 10px auto;
  box-shadow: 0 3px 5px 0 #a9a9a9;
  border-radius: 4px !important;
  padding: 0;
}

.unitBtns {
  max-width: 900px;
  margin: 15px auto !important;
}

.unitBtns .scopeBtn {
  display: flex;
}

.unitBtns .scopeBtn .btn {
  width: 33%;
  outline: none;
  padding: 2px 10px;
  box-shadow: 0 3px 5px 0 #666;
}

.unitBtns .scopeBtn .btn:last-of-type {
  width: 35%;
}

.unitBtns .scopeBtn .btn:active,
.unitBtns .btn-success:active {
  transform: translateY(3px);
}

.unitBtns .btn-success {
  padding: 13px 26px;
  box-shadow: 0 3px 5px 0 #666;
}

.route-loader {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.route-loader .spinner-border {
  margin-top: 0;
  width: 3.6rem !important;
  height: 3.6rem !important;
}

.form-control,
.css-yk16xz-control {
  border-radius: 0 !important;
}

.availablity label {
  margin: 7px 7px 0 0;
}

.availablity .form-control {
  width: 50%;
}

.d-inherit {
  display: inherit;
}

.tableLoader {
  padding: 10px 10px;
  position: relative;
  display: flex;
  justify-content: center;
}

.user-email a {
  color: #000000 !important;
}

.availablity-btn {
  margin-left: 8px;
  font-size: 16px;
  color: #204654 !important;
  cursor: pointer;
  font-weight: 700;
}

.modal-header svg {
  cursor: pointer !important;
}

.select-date {
  background-color: #0069d9;
  border-radius: 0 !important;
}

.select-date:focus {
  box-shadow: none !important;
}

.location-details {
  text-align: left;
  padding: 5px 15px 0 15px;
  cursor: pointer;
}

.location-details:hover {
  background-color: #f2f2f2;
}

.location-details hr {
  margin-bottom: 0;
}

.location-details p {
  padding-bottom: 0;
  margin-bottom: 0;
}

.location-details span {
  color: #2e6680;
}

.table-container {
  max-height: 40vh;
  overflow-y: scroll;
}

.customer-add {
  max-height: 40px;
  margin-right: 5px;
}

@media (max-width: 767px) {
  .col-8 .w-75 {
    width: 100% !important;
  }

  .col-8 .ml-4,
  .col-8 .ml-3,
  .col-12 .ml-4 {
    margin-left: 0 !important;
  }

  .container-fluid .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .container-fluid .card-body .row {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }

  .unitBtns .scopeBtn .btn,
  .unitBtns .scopeBtn .btn:last-of-type {
    width: 100%;
  }

  .unitBtns {
    flex-direction: column-reverse;
  }
}

.sidenav {
  height: calc(100vh - 70px);
  margin-top: 77px;
  width: 200px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #2a333d;
  overflow-x: hidden;
  padding-top: 20px;
}

.sidenav a {
  padding: 8px 0;
  text-decoration: none;
  font-size: 20px;
  color: #fff;
  display: block;
  border-bottom: 1px solid #212931;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.main-nav {
  width: calc(100vw - 220px);
  margin-left: 200px;
}

.nav-top-margin {
  margin-top: 96px;
}

.overriden-hover:hover {
  box-shadow: 1px 4px 13px 1px #969595 !important;
}

.selected-link {
  font-weight: bold;
  background: #495766;
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-theme-balham .ag-ltr .ag-cell {
  text-align: center;
}

/* side nav styling */
.sidenav a:hover {
  text-decoration: none;
}

/* order history table styling */
.history thead {
  background: #f5f7f7;
}

.history thead tr th {
  color: rgba(0, 0, 0, 0.54) !important;
  font-weight: 600;
  font-size: 11px;
}

.history tbody tr td {
  font-size: 10px;
}

.safety {
  background: #327fb5;
  color: white;
}

.safety span:first-of-type {
  color: rgb(255, 255, 255) !important;
  font-weight: 900;
  font-size: 15px;
}

.safety span:last-of-type {
  font-size: 12px;
  padding: 0px 60px;
}

/* pagination styling */
.pagination {
  justify-content: center;
}

.pagination .previous {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #ffffff;
  background-color: #2a333d;
  border: 1px solid #2a333d;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.pagination .next {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #ffffff !important;
  background-color: #2a333d;
  border: 1px solid #2a333d;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.pagination .next a,
.pagination .previous a {
  color: #ffffff;
}

.pagination .next a:hover,
.pagination .previous a:hover {
  text-decoration: none;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #48a2b8;
  border-color: #48a2b8;
}

.pagination .next:hover,
.pagination .previous:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #131313;
  border-color: #dee2e6;
}

.page-link:hover {
  color: white !important;
  background-color: #48a2b8 !important;
}

.pagination .page-link {
  color: #333;
  box-shadow: none;
  background-color: #e5e5e5;
  border: 1px solid #cacaca;
}

.table td,
.table th {
  vertical-align: middle;
}

.btn-shadow {
  box-shadow: 1px 2px 3px 0px #666;
}

.container-logo {
  width: 140px;
}

.review-container {
  display: flex;
  flex-direction: column;
  max-width: 660px;
  margin: 0 auto;
  /* height: 100vh; */
  /* align-items: center; */
  justify-content: center;
}

.review-container .logo {
  /* margin-bottom: 30px; */
}

.review-container .logo img {
  width: 150px;
}

.review-container .customer-review {
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
}

.review-container .customer-review p {
  margin-bottom: 0;
  margin-top: 10px;
}

.review-container form {
  padding: 0 20px;
}

.review-container form .form-control {
  border-radius: 5px !important;
  padding: 7px 10px;
  transition: 0.2s all ease;
  outline: none;
}

.review-container form .form-control:focus {
  border-color: #0000000f;
  box-shadow: inset 0px 1px 7px 0px #ccc;
}

.review-container form .submit-btn {
  background: #007bff;
  color: white;
}

.submitted-container {
  height: 100vh;
}

/* responsive for tablet */
@media (max-width: 991px) {
  .availablity .mb-4 .col-12 {
    justify-content: center;
  }

  .availablity .mb-4 .col-12:nth-of-type(2) {
    text-align: center;
    margin-top: 10px;
  }

  .dropdown-menu.show {
    display: inline-block;
    text-align: center;
    background: #f2f2f2;
  }
}

@media (max-width: 767px) {
  .login-container form {
    padding: 0 2% 10% 2%;
  }
  .search-crtr {
    margin-left: 2%;
    margin-right: 2%;
  }

  .location-loader {
    left: 45%;
  }

  .mobile-font-size {
    font-size: 12px;
  }
}

.portal-link {
  margin-top: 8px;
  padding: 0px 20px;
  color: #706d7a;
  text-decoration: none;
}

.portal-link:hover {
  text-decoration: none;

  color: #706d7a;
}

.error {
  border: 1px solid red;
}
.error-message {
  color: red;
  font-size: 15px;
  text-align: center;
}

.fa-check {
  color: green;
  margin: 14px 0 0 10px;
}

.work-order-image {
  max-width: 400px;
  margin: 10px 0 0 9px;
}
.work-order-detail-card {
  background: #efefef !important;
  margin: 10px auto;
  box-shadow: 0 3px 5px 0 #a9a9a9;
  border-radius: 4px !important;
  padding: 15px;
}
.caret {
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  color: #0056b3;
}
.animated {
  animation: appear 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes appear {
  0% {
    height: 0;
    transform: scaleY(0);
    transform-origin: top;
  }

  100% {
    height: auto;
    transform: scaleY(1);
    transform-origin: top;
  }
}

.text-alignment {
  vertical-align: super;
}

.review-textarea {
  background-color: #bedbec;
  border: 1px solid #285aa4;
}

.review-cursor {
  cursor: pointer;
}

.overview-submit-spinner {
  position: absolute;
  left: 10px;
  bottom: 6px;
}

.review-border {
  border-style: solid;
  border-width: 1px;
  border-color: #cdc7c4;
}

.footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  display: block;
  width: 100%;
  right: 0;
}

.unsubscribe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 420px);
}

.calendar-daily-table tbody > .daily-status {
  color: white;
}

.calendar-daily-table thead > tr {
  background: #4472c4;
  color: white;
}

.calendar-daily-table tbody > tr > .daily-price-tubs {
  color: white;
}

.calendar-daily-table tbody > tr > .daily-address {
  background: #d9d9d9;
  color: black;
}

.calendar-daily-table td {
  padding: 0;
  font-weight: 600;
}

.customer-workorder-loader {
  height: 309px;
}

.customer-past-order-table {
  height: 255px;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  font-size: 14px;
}

.customer-past-order-header button {
  font-size: 14px;
}

.customer-past-order-header input {
  font-size: 14px;
}

.default-select-dropdown {
  background: white;
  padding-right: 10px;
}

.default-select-dropdown > select {
  border: 0;
}

.default-select-dropdown > select:focus {
  border: 0;
  box-shadow: none !important;
}

.customer-past-order-dropdown {
  background: #e9ecef;
  padding-right: 0.5em;
  border: 1px solid #ced4da;
}

.customer-past-order-dropdown > select {
  border: 0;
  background: #e9ecef;
  font-size: 14px;
}

.customer-past-order-dropdown > select:disabled {
  background: #e9ecef;
}

.customer-past-order-dropdown > select:focus {
  box-shadow: none;
  background: #e9ecef;
}

/* Table Striped styles */
.selected-bundle-table > thead > tr {
  background-color: #4472c4 !important;
  color: white !important;
}

.selected-bundle-table > thead > tr small {
  color: white !important;
}

.selected-bundle-table > tbody > tr:nth-child(odd) {
  background-color: #cfd5ea !important;
}

.selected-bundle-table > tbody > tr:nth-child(even) {
  background-color: #e9ebf5 !important;
}

.increment-decrement-icon {
  cursor: pointer;
}

.ag-center-cols-container {
  width: unset !important;
}

.ag-cell {
  text-align: left !important;
}

.work-order-detail-card {
  background: #efefef !important;
  margin: 10px auto;
  box-shadow: 0 3px 5px 0 #a9a9a9;
  border-radius: 4px !important;
  padding: 15px;
}

.white-space-pre {
  white-space: pre-line;
}

/* Table Striped styles */
.table-striped > thead > tr {
  background-color: #4472c4 !important;
  color: white !important;
}

.table-striped > thead > tr small {
  color: white !important;
}

.table-striped > tbody > tr:nth-child(odd) {
  background-color: #cfd5ea !important;
}

.table-striped > tbody > tr:nth-child(even) {
  background-color: #e9ebf5 !important;
}

.tab-without-unit {
  background-color: red !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.mt-25 {
  margin-top: 25px;
}
@media (max-width: 480px) {
  .search-crtr {
    margin-left: 2%;
    margin-right: 2%;
  }
  .smal-font {
    font-size: x-small !important;
  }
}
@media (min-width: 600px) {
  .search-crtr {
    margin-left: 15%;
    margin-right: 15%;
  }
  .customer-table {
    margin-left: 15%;
    margin-right: 15%;
  }
}

.avoid-focus {
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}
